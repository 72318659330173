import Button from '@material-ui/core/Button';
import countries from '@vc/common/dist/constants/countries';
import languages from '@vc/common/dist/constants/languages';
import divisionsMap from '@vc/common/dist/divisionsMap';
import CountrySelection from 'components/CountrySelection';
import LanguageSelection from 'components/LanguageSelection';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DivisionsSelection from './components/DivisionsSelection';
import ThemeSelection from './components/ThemeSelection';
import VersionInfo from './components/VersionInfo';
import YearsSelection from './components/YearsSelection';
import { loadFontFamily } from './utils/font';
import restartVcApp from './vcApp';

const Container = styled('div')`
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;

    > * {
        margin: 8px;
    }
`;

const ButtonContainer = styled('div')`
    margin-left: 8px;
`;

const defaultDivisions = window.HOST_OPTIONS.divisions ? window.HOST_OPTIONS.divisions : [divisionsMap[0]];
const defaultCountry = window.HOST_OPTIONS.country || countries[0];
const defaultLanguage = window.HOST_OPTIONS.language || languages[0];
const defaultModelYear = window.HOST_OPTIONS.modelYear ? window.HOST_OPTIONS.modelYear : 2020;
const defaultTheme = '';

const App: React.FC = () => {
    const [divisions, setDivisions] = useState<string[]>(defaultDivisions);
    const [country, setCountry] = useState<StartOptions['country']>(defaultCountry);
    const [language, setLanguage] = useState<string>(defaultLanguage);
    const [modelYear, setModelYear] = useState<number>(defaultModelYear);
    const [theme, setTheme] = useState<string>(defaultTheme);
    useEffect(() => {
        loadFontFamily();
    }, []);

    function handleDivisionChange(event: React.ChangeEvent<{ value: unknown }>): void {
        const values = (event.target.value as unknown) as string[];

        setDivisions(values);
    }

    function handleCountryChange(event: React.ChangeEvent<{ value: unknown }>): void {
        const value = (event.target.value as unknown) as StartOptions['country'];

        setCountry(value);
    }

    function handleLanguageChange(event: React.ChangeEvent<{ value: unknown }>): void {
        const value = (event.target.value as unknown) as string;

        setLanguage(value);
    }

    function handleModelYearChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setModelYear(parseInt(event.target.value));
    }

    function handleThemeChange(event: React.ChangeEvent<{ value: unknown }>): void {
        setTheme(event.target.value as string);
    }

    function handleGo(): void {
        restartVcApp({ ...window.HOST_OPTIONS, divisions, modelYear, country, language });
    }

    return (
        <div>
            <VersionInfo></VersionInfo>

            <Container>
                <DivisionsSelection divisions={divisions} onChange={handleDivisionChange}></DivisionsSelection>
                <YearsSelection year={modelYear} onChange={handleModelYearChange}></YearsSelection>
                <CountrySelection
                    countries={countries}
                    value={country}
                    onChange={handleCountryChange}
                ></CountrySelection>
                <LanguageSelection
                    languages={languages}
                    value={language}
                    onChange={handleLanguageChange}
                ></LanguageSelection>
                <ButtonContainer>
                    <Button variant="outlined" size="small" onClick={handleGo}>
                        Go
                    </Button>
                </ButtonContainer>
                <ThemeSelection theme={theme} onChange={handleThemeChange} onGo={handleGo}></ThemeSelection>
            </Container>
        </div>
    );
};

export default App;
