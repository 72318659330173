import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
    align-items: center;
    display: flex;
`;

const TextFieldContainer = styled('div')`
    width: 85px;
`;

type Props = {
    year: number;

    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const YearsSelection: React.FC<Props> = ({ year, onChange }) => {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        onChange && onChange(event);
    }

    const currentYear = new Date().getFullYear();

    return (
        <Container>
            <TextFieldContainer>
                <TextField
                    style={{ width: '100%' }}
                    id="modelYear"
                    label="Model Year"
                    value={year}
                    onChange={handleChange}
                    margin="none"
                    type="number"
                    inputProps={{ min: currentYear, max: currentYear + 1 }}
                />
            </TextFieldContainer>
        </Container>
    );
};

export default YearsSelection;
