export type ThemeOption = {
    name: string;
    css: CssVariables;
};

export type CssVariables = {
    primary: string;
    primaryhover: string;
    secondary: string;
    secondaryhover: string;
    cta: string;
    ctahover: string;
    maintextfont: string;
    headingfont: string;
};

const themes: ThemeOption[] = [
    {
        name: 'Chevy',
        css: {
            primary: '#f2bf24',
            primaryhover: '#d6a40d',
            secondary: 'gray',
            secondaryhover: '#676767',
            cta: '#f2bf24',
            ctahover: '#d6a40d',
            maintextfont: 'Roboto, sans-serif',
            headingfont: 'Louis, sans-serif',
        },
    },
    {
        name: 'Lexus',
        css: {
            primary: '#000',
            primaryhover: '#000',
            secondary: '#fff',
            secondaryhover: '#e6e6e6',
            cta: '#000',
            ctahover: '#000',
            maintextfont: 'Nobel, sans-serif',
            headingfont: 'Nobel, sans-serif',
        },
    },
    {
        name: 'Toyota',
        css: {
            primary: '#eb0a1e',
            primaryhover: '#ba0818',
            secondary: '#58595b',
            secondaryhover: '#3f4041',
            cta: 'green',
            ctahover: '#004d00',
            maintextfont: 'ToyotaType, sans-serif',
            headingfont: 'ToyotaType, sans-serif',
        },
    },
    {
        name: 'Toyota of South Florida',
        css: {
            primary: '#d42635',
            primaryhover: '#a91e2a',
            secondary: '#121212',
            secondaryhover: '#000',
            cta: 'green',
            ctahover: '#004d00',
            maintextfont: 'Prompt, sans-serif',
            headingfont: 'Prompt, sans-serif',
        },
    },
];

export default themes;
