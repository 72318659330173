import WebFont from 'webfontloader';

export async function loadFontFamily(font = 'Nubito'): Promise<void> {
    const fontTmp = font.toLowerCase();

    switch (fontTmp) {
        case 'nobel':
            try {
                await import(/* webpackChunkName: "nobel-fonts"*/ 'styles/fonts/nobel.css');
            } catch (error) {
                console.error(error);
            }
            return;

        case 'louis':
            try {
                await import(/* webpackChunkName: "louis-fonts"*/ 'styles/fonts/louis.css');
            } catch (error) {
                console.error(error);
            }
            return;

        case 'toyotatype':
            try {
                await import(/* webpackChunkName: "toyota-fonts"*/ 'styles/fonts/toyota.css');
            } catch (error) {
                console.error(error);
            }
            return;

        case 'roboto':
            WebFont.load({
                google: {
                    families: ['Roboto:400,700,900'],
                },
            });
            return;

        case 'prompt':
            WebFont.load({
                google: {
                    families: ['Prompt:400,700,900'],
                },
            });
            return;

        case 'nubito':
        default:
            WebFont.load({
                google: {
                    families: ['Nunito:400,700,900'],
                },
            });
            return;
    }
}

export function parseFirstFontName(fontFamily: string): string {
    return fontFamily.split(',')[0];
}
