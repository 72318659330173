import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
    align-items: center;
    display: flex;
`;

type Props = {
    languages: string[];
    value: string;

    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

const LanguageSelection: React.FC<Props> = ({ languages, value, onChange }) => {
    return (
        <Container>
            <FormControl>
                <InputLabel htmlFor="languages">Language</InputLabel>
                <Select name="languages" value={value} onChange={onChange} input={<Input id="select" />} margin="none">
                    {languages.map(m => (
                        <MenuItem key={m} value={m}>
                            {m}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Container>
    );
};

export default LanguageSelection;
