import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { sortBy } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { loadFontFamily, parseFirstFontName } from '../../utils/font';
import themes, { CssVariables, ThemeOption } from './themes';

const Container = styled('div')`
    align-items: center;
    display: flex;
`;

const ButtonContainer = styled('div')`
    margin-left: 8px;
`;

type Props = {
    theme: string;

    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    onGo: () => void;
};

const ThemeSelection: React.FC<Props> = ({ theme, onChange, onGo }) => {
    async function handleOnGo(): Promise<void> {
        const selectedTheme = themes.find(f => f.name === theme) as ThemeOption;

        if (selectedTheme) {
            (Object.keys(selectedTheme.css) as (keyof CssVariables)[]).forEach(f =>
                document.documentElement.style.setProperty(`--${f}`, selectedTheme.css[f])
            );

            const { maintextfont, headingfont } = selectedTheme.css;

            await loadFontFamily(parseFirstFontName(maintextfont));
            await loadFontFamily(parseFirstFontName(headingfont));
        }

        onGo();
    }

    return (
        <Container>
            <FormControl>
                <InputLabel htmlFor="theme" shrink>
                    Theme
                </InputLabel>
                <Select
                    style={{ minWidth: 80 }}
                    name="theme"
                    value={theme}
                    onChange={onChange}
                    margin="none"
                    displayEmpty
                >
                    <MenuItem value="">-</MenuItem>
                    {sortBy(s => s.name, themes).map(m => (
                        <MenuItem key={m.name} value={m.name}>
                            {m.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <ButtonContainer>
                <Button variant="outlined" size="small" onClick={handleOnGo}>
                    Go
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default ThemeSelection;
