import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import divisionsMap from '@vc/common/dist/divisionsMap';
import React from 'react';
import styled from 'styled-components';

const Container = styled('div')`
    align-items: center;
    display: flex;
`;

type Props = {
    divisions: string[];

    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

const DivisionsSelection: React.FC<Props> = ({ divisions, onChange }) => {
    return (
        <Container>
            <FormControl>
                <InputLabel htmlFor="divisions">Divisions</InputLabel>
                <Select
                    multiple
                    name="divisions"
                    value={divisions}
                    onChange={onChange}
                    input={<Input id="select-multiple" />}
                    margin="none"
                >
                    {divisionsMap.map(m => (
                        <MenuItem key={m} value={m}>
                            {m}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Container>
    );
};

export default DivisionsSelection;
