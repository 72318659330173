// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../app/src/types/window.d.ts" />

import { createThemeConfig } from '@vc/common/dist/utils/themeConfig';

export default function restartVcApp(options: StartOptions): void {
    window.VehicleConfigurator.unmount();

    const style = getComputedStyle(document.documentElement);

    options = {
        ...options,
        theme: createThemeConfig(style),
    };

    window.VehicleConfigurator.start(options);
}
