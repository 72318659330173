import React, { useEffect, useState } from 'react';

const VersionInfo: React.FC = () => {
    const [version, setVersion] = useState('');

    function handleVcLoaded(): void {
        const { APP_VERSION, BUILD_NUMBER } = window.VehicleConfigurator;

        if (!APP_VERSION) {
            return;
        }

        setVersion(`${APP_VERSION}${BUILD_NUMBER ? `+${BUILD_NUMBER}` : ''}`);
    }

    useEffect(() => {
        window.addEventListener('vc:loaded', handleVcLoaded);

        return (): void => {
            window.removeEventListener('vc:loaded', handleVcLoaded);
        };
    });

    if (!version) {
        return null;
    }

    return (
        <div>
            <strong>#{version}</strong>
        </div>
    );
};

export default VersionInfo;
